import React, { useState } from 'react';
import './styles/faq.css';

const faqs = [
  {
    question: "What happened?",
    answer: "The core administrative team, deeply engaged with the community, has assumed control of memdex.live and the associated intellectual property, which includes the original code base. This action was necessitated by the previous project backers' failure to fulfill promises, resulting in delayed, inadequate development and flawed communication."
  },
  {
    question: "What is expected from us?",
    answer: "Our priority is to rebuild community trust through consistent, transparent actions. We acknowledge the discrepancies between the current MDX and our utility-driven MEMDEX FUND (MDF), which is significantly more advanced. We have already implemented multi-chain functionality and are developing diverse basket options (Top 100, 50, 25, 10, 5, and custom). Contracts with the developer are finalized, ensuring continued progress. While we acknowledge past issues, they do not define our team or our commitment to the project's future. We will focus on delivering the promised utility, minimizing distractions related to past events."
  },
  {
    question: "How do we plan to achieve the MemDex rebirth?",
    answer: "Our team of over 30 skilled professionals is dedicated to revitalizing $MEMDEX. We are committed to rebuilding the MEMDEX FUND (MDF) with integrity, transparency, and active community participation. We recognize the community's vital role in $MEMDEX's success and will work collaboratively to achieve our shared goals."
  },
  {
    question: "Who is DevilKing (the developer)?",
    answer: "DevilKing (DK) was the original developer of the core utility, which is now the foundation of MDF. Due to unpaid work and misaligned project direction, DK ceased development, maintaining professional silence. He refused to hand over the original code. Consequently, the previous team resorted to copying publicly available MPX code, repackaging it as MDX, which did not align with community expectations. We have now secured ownership of DK's original code, which is being developed into the robust MDF. This ensures that MDF represents the intended vision for MDX."
  }
];

function FAQMDF() {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="faq-container">
    <a href="/">
      <img src="/images/mdx.png" alt="MDX Logo" className="mdx-image" />
    </a>
      <h1 className="under-construction">MDF FAQ Under Construction</h1>

 {/*     <h1 className="faq-title">Frequently Asked Questions</h1>
      {faqs.map((faq, index) => (
        <div key={index} className="faq-item">
          <button className="faq-question" onClick={() => toggleFAQ(index)}>
            {faq.question}
          </button>
          {openIndex === index && (
            <div className="faq-answer">{faq.answer}</div>
          )}
        </div>
      ))} */}

      {/* Link to the PDF file */}
  {/*    <a
        href="/files/MemDex%20Foundation%20FAQ.pdf"
        target="_blank"
        rel="noopener noreferrer"
        className="full-faq-link"
      >
        View Full FAQ Document
      </a> */}
    </div>
  );
}

export default FAQMDF;