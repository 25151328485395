import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Landing from './components/Landing';
import FAQMDF from './components/FAQMDF';
import Footer from './components/Footer';
import './App.css'; // Import the main CSS file for the app layout

function App() {
  return (
    <Router>
      <div className="app-container">
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/faq" element={<FAQMDF />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;