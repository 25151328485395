import React, { useState } from 'react';
import { FaTelegramPlane, FaDiscord } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { useNavigate } from 'react-router-dom';
import './styles/landing.css';
import FAQHome from './FAQHome';

function Landing() {
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false); // State to toggle the announcement

  const toggleAnnouncement = () => {
    setIsExpanded(!isExpanded); // Toggle the expanded state
  };

  return (
    <div className="landing-container">
      <img src="/images/mdx.png" alt="MDX Logo" className="mdx-image" />
      <h1 className="under-construction">Website Under Construction</h1>
      <div className="button-container">
        <button
          className="mdx-button"
          onClick={() => window.location.href = "https://mdx.memdex.live"}
        >
          MDX
        </button>
      </div>

      {/* Added Announcement */}
      <div className="announcement">
        <h2>Important Announcement</h2>
        <p>
          Dear Friends, we, the core admins and guardians of $MEMDEX, are writing to you today with a crucial announcement regarding the future of our beloved community and project. After extensive deliberation and careful consideration, we believe it is best to take the project in a new direction...
        </p>
        {isExpanded && (
          <p>
            This decision was not made lightly. It stems from a profound realization that the original team does not have the community's best interests at heart. We have witnessed firsthand the failure to provide proper communication and proof of work and failure to deliver on promised utilities, most notably the $MDX utility index fund. The current state of this fund is demonstrably minimal, poorly constructed, and falls far short of initial expectations. Our attempts to seek clarity and answers to fundamental questions were met with obstruction and, in many instances, outright deception. As some have surmised, this project was largely a facade from the beginning. We have uncovered everything and it is completely unacceptable.

            So we are here, with the fire of revolution in our hearts, and a fully fledged team of developers, UI designers and financial backers to make it real and make it right.

            Faced with this revelation of treachery, we felt compelled to act, and indeed we have. We refuse to allow $MEMDEX to languish and fade away. The bonds forged within our community, particularly through our vibrant voice chat, have become invaluable. Magical even. This space has evolved into more than just a crypto discussion forum; it's an international crypto university, a news hub, a market analysis platform, an emotional support network, a spiritual memecoin temple and a place for individuals from diverse backgrounds, from all over the beautiful world, to unite and pursue our shared aspirations.

            Inspired by these connections, we have assembled a team of over 20 dedicated and highly skilled individuals, cryptological prodigies committed to revitalizing $MEMDEX and realizing its full potential.

            And so... We are proud to introduce the MemDex Foundation, a new iteration of $MEMDEX driven by experienced back-end developers, UI designers, marketing strategists, legal experts, financiers, and passionate community members. Our shared vision is to rebuild the Index Fund (which shall henceforth be called the MEMDEX FUND: $MDF) with honesty, transparency, and active community participation. We recognize that the community is the lifeblood of $MEMDEX, and together, we can achieve limitless success.

            Our team is already diligently working on developing a fully functional, cross-chain index fund for top memecoins. The way it was supposed to be and better! Full roadmap and updates on the way!

            Crucially, we want to emphasize that WE ARE NOT CREATING A NEW TOKEN. We are solely focused on developing a new and robust index fund. We are committed to $MEMDEX and continue to HODL and DCA $MEMDEX like never before. Our wallet distribution is healthy, the chart is strong, there are no wallets from the old team that pose any threat, liquidity is locked forever, the burn wallet remains the top holder, and we are perfectly positioned to take this to the moon.

            We are committed to providing regular, detailed updates, proof of work, and transparent communication throughout this process. We will not leave you in the dark. While this transformation will require time, we pledge to move swiftly and keep you informed every step of the way. Questions will be answered! Concerns will be addressed! Good ideas will be integrated! Honesty, care and competance will be the new foundations of our success.

            We understand that this news may be overwhelming, but we are confident that our community comprehends the necessity of this action and the immense potential it holds.
          </p>
        )}
        <button className="read-more" onClick={toggleAnnouncement}>
          {isExpanded ? 'Read Less' : 'Read Full Statement'}
        </button>
      </div>

      <img src="/images/MDFComingSoon.png" alt="MDf Logo" className="mdf-comingsoon" />

<FAQHome />

      <div className="socials">
        <a href="https://t.me/+mwZE71Uu6XphODdh" target="_blank" rel="noopener noreferrer">
          <FaTelegramPlane size={32} />
        </a>
        <a href="https://x.com/MemdexSociety" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faXTwitter} size="2x"  />
        </a>
        <a href="https://discord.gg/KDzRhqjg" target="_blank" rel="noopener noreferrer">
          <FaDiscord size={32} />
        </a>
        <a href="https://dexscreener.com/solana/7cjge6pwonqfxjayuvude7tcs2gmpnnp1jct29jt3jvj" target="_blank" rel="noopener noreferrer">
          <img src="/images/dexscreener.png" alt="DexScreener" className="social-icon" />
        </a>
        <a href="https://www.coingecko.com/en/coins/memdex100" target="_blank" rel="noopener noreferrer">
          <img src="/images/CoinGecko.svg" alt="CoinGecko" className="social-icon" />
        </a>
      </div>
    </div>
  );
}

export default Landing;